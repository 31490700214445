import FunctionBroadcastChannel from 'o365.modules.FunctionBroadcastChannel.ts';

async function asyncInit() {
    if (window.top != window.self) { return; }
    const { getLiteClient } = await import('team-hub-service');
    const client = getLiteClient()
    client.onMessage(() => {
        window.contextSelector.reloadChatsIndicator()
    });
}

const channel = new FunctionBroadcastChannel({
    id: 'o365-team-chats-navbar',
    functions: {
        'updateNavbarNotifications': () => {
            window.contextSelector.reloadChatsIndicator()
            return Promise.resolve('');
        }
    }
})

function notifyLastReadUpdate() {
    channel.execute('updateNavbarNotifications').catch(() => {
        // silent fail, no callback expected
    });
}

asyncInit().catch((ex) => {
    console.warn(ex);
});

export { notifyLastReadUpdate };
